export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.containerScrolled': {
      backgroundColor: 'black',
      '.navItem a': {
        color: 'white'
      }
    },
    '.navItem a': {
      ':hover': {
        color: 'secondary'
      }
    }
  },

  '.navItem': {
    ':hover': {
      color: 'primary'
    }
  },

  scrollProgressBarV1: {
    '.progressBar': {
      backgroundColor: 'black'
    }
  },

  footer: {
    borderTop: '2px solid',
    borderColor: 'secondary',
    backgroundColor: 'black',
    img: {
      padding: ['0 3.5rem', '', '0']
    },
    '.multiButtonContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        display: 'inline-block',
        margin: [0, '', '0.5rem'],
        padding: ['0'],
        width: ['42%', '', 'auto']
      }
    },
    a: {
      fontSize: '18px!important',
      color: 'white!important',
      svg: {
        width: 24,
        height: 24,
        margin: '0 0.5rem'
      }
    }
  },

  ctaWidget: {
    a: {
      borderColor: 'secondary'
    },
    'a:hover': {
      'svg path': {
        fill: 'white'
      }
    },
    'svg path': {
      fill: 'secondary'
    },
    '#reservations-cta': {
      display: ['flex', 'flex', 'none', 'none']
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['3rem', '', '4rem'],
    textTransform: 'uppercase',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '1.5rem',
    color: 'white'
  },
  subtitle: {
    color: 'white',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['2rem', '', '2.5rem'],
    marginBottom: '1.5rem',
    '::before': {
      content: "''",
      backgroundColor: 'white',
      width: '10%',
      height: '3px'
    },
    '::after': {
      content: "''",
      backgroundColor: 'white',
      width: '10%',
      height: '3px'
    }
  },
  text: {
    textAlign: 'center',
    p: {
      textAlign: 'center',
      fontSize: 'inherit'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.muteToggle': {
      display: 'none'
    },
    '.videoContainer': {
      height: '100vh'
    },
    '.videoContainer:before': {
      background: 'linear-gradient(rgb(0 0 0 / 40%), rgb(0 0 0 / 2%), rgb(0 0 0 / 0%)) !important'
    },
    height: ['', '', 'calc(100vh + 30px)'],
    padding: ['', '', '0rem'],
    boxShadow: 'inset 1px -15px 20px #202020',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3rem',
    backgroundColor: 'transparent',
    // backgroundImage: "url('https://res.cloudinary.com/gonation/image/upload/v1668013810/sites/barber-shoppe/background-img.jpg')",
    '.lazyload-wrapper': {
      padding: '2.5%',
      width: ['100%', '', '50%'],
      display: 'flex',
      justifyContent: 'center',
      '.image': {
        boxShadow: `0 0 8px 8px white inset`,
        maxWidth: ['100%', '500px', '620px'],
        objectFit: 'cover',
        height: ['auto', 'auto', '80%'],
        margin: 'auto'
      }
    },

    '.content': {
      width: ['100%', '', '50%'],
      backgroundImage:
        "url('https://res.cloudinary.com/gonation/image/upload/v1668020654/sites/barber-shoppe/barbershoppe_final_logo-01.png')",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: ['350px', '', '350px'],
      alignSelf: 'center',
      backgroundColor: 'rgba(255, 255, 255, .5)',
      paddingLeft: '1rem'
    },
    '.hero_content_container': {
      display: ['none', '', 'flex'],
      bottom: '2rem',
      left: '2rem',
      border: 'none!important'
    },

    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '', '5rem', '6rem'],
      display: 'none!important'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      display: 'none!important'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.25rem', '', '1.5rem']
    },
    '.ctaButton': {
      backgroundColor: 'secondary',
      fontSize: '1.3rem',
      textTransform: 'uppercase',
      letterSpacing: '2px',
      color: 'black',
      border: 'none!important',
      padding: '1rem 6rem',
      justifyContent: 'center',
      width: '100%',
      boxShadow: '#000 5px 10px 20px',
      display: ['none', 'none', 'flex', 'flex']
    }
  },

  homepageHeroShout: {},

  contentBoxesTitle1: {
    // padding: ['1rem', '2rem 1rem', '2rem'],
    color: 'white',
    padding: '1rem',
    '::before': {
      content: "'1'",
      fontSize: ['5rem', '', '', '10rem'],
      marginRight: '1rem'
    },
    '.section': {
      alignItems: 'flex-start',
      margin: '1rem'
      // maxWidth: ['', '', '70%'],
    },
    '.title': {
      textAlign: 'left',
      fontSize: ['2rem', '3rem', '4.5rem'],
      letterSpacing: '-2px',
      fontFamily: 'display',
      fontWeight: 'normal'
    }
  },

  contentBoxesTitle2: {
    color: 'white',
    variant: 'customVariants.contentBoxesTitle1',
    paddingLeft: '20%',
    '::before': {
      content: "'2'",
      fontSize: ['5rem', '', '', '10rem'],
      marginRight: '1rem'
    }
  },

  contentBoxesTitle3: {
    color: 'white',
    variant: 'customVariants.contentBoxesTitle1',
    paddingLeft: '40%',
    '::before': {
      content: "'3'",
      fontSize: ['5rem', '', '', '10rem'],
      marginRight: '1rem'
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black']
    },
    '.text': {
      fontFamily: 'Handy Casual Condensed',
      fontSize: ['1.6rem', '', '2rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontFamily: 'display',
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['black', '', '', 'secondary'],
      color: ['secondary', '', '', 'dark']
    },
    '.date': {
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    padding: '1rem',
    '.lazyload-wrapper': {
      maxHeight: '75vh'
    },
    '.content': {
      border: 'solid 2px',
      backgroundColor: 'black',
      alignItems: 'center',
      color: 'white'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '', '3rem']
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      p: {
        color: 'white'
      }
    },
    '.ctaButton': {
      backgroundColor: 'secondary',
      borderColor: 'secondary',
      borderWidth: '2px',
      color: 'black',
      transition: 'all .3s ease-in-out',
      ':hover': {
        color: 'secondary',
        backgroundColor: 'transparent',
        transition: 'all .3s ease-in-out'
      }
    }
  },

  homepageEvent: {
    variant: 'customVariants.homepageAbout',

    ctaButton: {
      backgroundColor: 'red'
    }
  },

  homepageSauce: {
    variant: 'customVariants.homepageAbout'
  },

  homepageGallery: {
    variant: 'customVariants.homepageAbout'
  },
  homepageInstagram: {
    '.logoAndTextContainer ': {
      // margin: '3rem 0rem 2rem',
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },

  homepageContactUs: {
    variant: 'customVariants.homepageAbout',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      variant: 'customVariants.title',
      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2'
    },
    '.text': {
      order: '3'
    },
    a: {
      order: '5'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.title, .text': {
      color: 'white'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumName': {
      color: 'white'
    },
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    },
    '.albumsCTAActive': {
      opacity: 1,
      backgroundColor: 'secondary',

      '.albumTitle': {
        color: 'dark'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'white'
    },
    'h4, h5': {
      color: 'white'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      textTransform: 'uppercase',
      color: 'light'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      color: 'white',
      marginBottom: '1.5rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid',
      color: 'white'
    },
    'a, .time, .closed': {
      color: 'white'
    }
  },

  // ? ========================
  // ? =====  Team pages  =====
  // ? ========================

  teamMenu: {
    marginTop: ['5rem', '', '8rem'],
    padding: ['0rem 1rem', '0rem 1rem', '0rem 1rem', '0rem 1rem'],
    '.boxesHeading': {
      display: 'none'
    },
    '.boxesContainer': {
      // flexDirection: 'column',
      maxWidth: '767px',
      margin: '0rem auto'
    },
    '.box': {
      width: ['', '', 'calc(50% - 1rem)'],
      ':hover': {},
      a: {
        // display: 'flex',
        // flexDirection: ['', '', 'row'],
      }
    },
    '.textContent': {
      position: 'static'
    }
  },
  teamPage: {
    '.imageContainer': {
      width: ['', '', '40%']
    },
    '.contentRow': {
      width: ['', '', '60%'],
      padding: ['1rem', '1.5rem', '2.5rem', '3rem']
    },
    '.title': {
      color: 'white'
    },
    '.subtitle': {
      color: 'primary'
    }
  },

  // ? ========================
  // ? =======  blog   ========
  // ? ========================
  blogMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  blogPage: {
    '*, p, span': {
      color: 'white!important'
    },
    '.filtersMenu': {
      display: 'none'
    }
  }
}
